.app-header {
    display: flex;
    align-items: center;
    align-content: center;
    /* flex: 1; */
    padding: 0 1.5rem;
    background: #fff;
    padding: 10px;
    height: 55px;
    box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
                0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
                0 0.25rem 0.53125rem rgb(4 9 20 / 5%),
                0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}
.app-header ~ div {
    position: relative;
}
.app-header-left {
    display: flex;
    align-items: center;
}
.app-header-right {
    align-items: center;
    display: flex;
    gap: 1rem;
    margin-left: auto;
}