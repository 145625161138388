.screen {
  cursor: pointer;
  position: absolute;
  -webkit-transform: translate(-50%, 50%);  left: 50%;
  top: 50%;
  transform: translate(-50%, 50%);
  overflow: hidden;
  width: 330px;
  height: 360px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2 12px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

.screen #topIcon {
  position: absolute;
  left: 50%;
  top: 30%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.screen .border-top {
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  width: 100%;
  background-color: #1976d2;
}

.screen h3 {
  font-weight: 700;
  font-size: 24px;
  color: #606060;
  letter-spacing: 0;
  position: absolute;
  left: 50%;
  top: 55%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.screen .success-message, .success-message-2 {
  font-weight: 400;
  font-size: 16px;
  color: #616161;
  letter-spacing: 0.18px;
  position: absolute;
  left: 50%;
  top: 68%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.success-message-2{
  top: 75% !important;
}

.screen button {
  background: #5ae9ba;
  border: 1px solid #34e2a9;
  box-shadow: 0 3px 20px 0 rgba(90, 233, 186, 0.6);
  border-radius: 100px;
  letter-spacing: 1.5px;
  font-weight: 500;
  color: #fff;
  padding-top: 2px;
  width: 186px;
  height: 40px;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  bottom: -20%;
  opacity: 0;
  font-size: 13px;
  cursor: pointer;
}

.screen button:focus {
  outline: 0;
  cursor: pointer;
}
#Bubbles {
  visibility: hidden;
}

.un {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.tr {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.btn-overlay {
  background-color: #43d0f1;
  border: 0;
  color: #fff;
  opacity: 0.6;
  padding: 10px 15px;
  border-radius: 100px;
  font-size: 12px;
  letter-spacing: 0.8px;
  z-index: 999;
  width: 100px;
}

.btn-overlay:hover {
  opacity: 1;
}

#restart {
  position: fixed;
  right: 10px;
  top: 10px;
}

#invert {
  position: fixed;
  right: 10px;
  top: 55px;
}
