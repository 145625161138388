.settings .color-picker{
    text-transform: uppercase;
    letter-spacing: 1px;
}
.settings .card .style-properties{
    max-height: calc(100vh - 120px) !important;
    overflow-y: auto;
}
.settings table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
}
.settings table thead > tr > th {
    position: relative;
    color: #000000d9;
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid rgba(0,0,0,.06);
    transition: background .3s ease;
}
.settings table thead > tr > th,
.settings table tbody > tr > td{
    position: relative;
    padding: 5px 10px;
    overflow-wrap: break-word;
    border-bottom: 1px solid rgba(0,0,0,.06);
    border-right: 1px solid rgba(0,0,0,.06);
    width: 50%;
    transition: background .3s;
}
.settings table tbody > tr > td input,
.settings table tbody > tr > td input:focus,
.settings table tbody > tr > td .ant-input-number,
.settings table tbody > tr > td .ant-input-number-focused,
.settings table tbody > tr > td .ant-input-number:focus {
    outline: none;
    border: none;
    box-shadow: none;
}
.settings .anticon > svg {
    width: auto;
}
.settings .ellipse {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}