/* .ant-popover-inner-content .ant-form-item-control-input-content > * {
    width: 100%;
} */
.ant-popover-title {
    padding: 12px 10px;
}
.add-section {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
}
.add-section .anticon {
    height: 18px;
    width: 18px;
}
.add-section .anticon > svg {
    height: 100%;
    width: 100%;
}