.theme-selector {
    height: 40px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
    outline: none;
    border: none;
}
.theme-selector:hover {
    width: 100%;
    transition: all 0.3s ease-out;
    border-radius: 0 5px 5px 0;
}
.theme-selector p {
    padding: 0 5px;
}
.theme-selector.txt-white, .theme-selector.txt-white:hover{
    color: #fff;
    font-weight: 500;
}
.theme-selector.txt-black,.theme-selector.txt-black:hover {
    color: #000;
    font-weight: 500;
}
.react-flow__panel.react-flow__attribution.bottom.right {
    display: none;
}