.form-container {
    padding: 0.5rem 2rem;
    margin: 0 auto;
}
.form-container-profile {
    padding: 0.5rem 2rem;
    width: 100% !important;
    margin: 0 auto;
}
.form-group-input > div > input {
    padding: 8px !important;
}
.form-title {
    min-height: 39px;
    padding: 0.5rem 2rem;
    box-shadow: 0 0.46875rem 2.1875rem rgb(7 69 205 / 30%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(14 83 236 / 3%);
    background: rgb(241 244 246);
    position: sticky;
    top: 0;
    z-index: 9;
}
.form-title > h2 {
    color: #1976d2;
}
.form-grid {
    border-left: 8px solid #1976d2;
    border-radius: 10px;
}

.chipinput {
    width: 250px;
    margin-left: 30px;
    margin-top: 30px;
    box-shadow: none !important;
    margin: 0;
    display: inline;
  }
  .styles_chip-input__3aumB .styles_chip__i-PT7 {
    background-color: #0471be;
    border: #0471be;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    margin-left: 3px;
  }
  .styles_chip-input__3aumB .styles_chip__i-PT7 svg{
    fill: aliceblue;
  }
.chipinput .p-2 {
    flex: 0 0 auto;
    margin: 1px;
    max-width: 100%;
    display: inline-block;
    width: max-content;
}

.chipinput input{
    outline: none;
    border: none;
    height: 42px;
    margin-top: 10px;
}
  
  .styles_chip-input__3aumB .styles_chip__i-PT7:hover {
    background-color: #0471be;
  }
  
.control-label::after{
    content: '*';
    color: red;
    font-size: 20px;
    margin-left: 3px;
}

.error input {
    border: 1px solid red;
}
.error input:focus{
    border: 1px solid red;
}
.form-error{
    color: #c40000;
    padding: 10px;
}
.error .Mui-required{
    color: #c40000;
}
.error-label{
    color: #c40000;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

.input-scroll input[type=number]::-webkit-inner-spin-button,
.input-scroll input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
@media only screen and (max-width: 768px) {
    .form-container {
        width: 80% !important;
    }
}

@media only screen and (max-width: 576px) {
    .form-container {
        width: 100% !important;
    }
}

.display-none{
    display: none !important;
}

.edit-name {
    padding-bottom: 15px;
    font-size: 20px;
}