.elements {
  height: calc(100vh - 130px);
  overflow-x: auto;
}
.elements-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  background-color: inherit;
}
.elements-container .card {
  padding: 10px;
  display: flex;
  gap: 15px;
  flex-basis: 100%;
  flex-shrink: 1;
  flex-wrap: wrap;
  font-weight: 500;
  box-shadow: none !important;
  border-bottom: 1px solid #eee;
}
.elements-image .anticon {
  width: 18px;
  height: 18px;
}
.elements-image .anticon > svg {
  height: 100%;
  width: 100%;
}
.card-title {
  color: #fff;
  background-color: #66737a;
  height: 40px;
}
.card-title p {
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
}
