.flex{
    display: flex;
}
.input-contents{
    width: 105%;
}

.comments ::placeholder{
    padding-left: 10px;
}
.history-content{
    border-bottom: 2px solid #f0f0f0;
}
.name{
    height: 10px;
}

.grid-border {
    border-style: solid;
    border-width: thin;
    border: grey;
}
.ant-card-head{
    background: #ececec;
}
.icon-padding-rigt{
    padding-right: 10px;
}
.text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.profile-tracker-stepper > .Mui-completed svg{
    fill: #3bbe3b;
}

.menus-container{
    background: white;
    margin-bottom: 10px;
    padding: 3px;
}

.main-container { 
    background-color: inherit;
    padding: 0;
    margin: 10px auto 0 auto;
    padding: 20px;
}
.main-container > div {
    padding: 10px 6px;
}
.main-container > div >div {
    background-color: #fff;
    padding: 7px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.filter-options p{
    font-size: 12px;
    border: 1px solid;
    border-radius: 33px;
    padding: 2px 10px 2px 10px;
    margin-bottom: 8px !important;
    font-family: inherit;
    align-items: center;
    color: rgb(24, 144, 255)
}

.cloud-dwnld{
    margin-left: 6px;
}
.dropDown {
   height: 42px;
}

.radio-group{
    border: 1px solid rgba(0, 0, 0, 0.23);
     border-radius: 5px;
    padding-left: 10px
}
@media screen and (max-width: 860px) {
    .add-comment-popover .MuiPopover-paper{
        width: 50% !important;
    }
  }
.add-comment-popover .MuiPopover-paper{
    width: 20%;
}
.leave-tracker,.wfh-tracker{
    min-width: 83.5vw;
    padding-left: 0 !important;
}

 .leave-trackerdate,.wfh-trackerdate{
    display: flex !important;
    justify-content: center !important;
}



