.layout {
  width: 100%;
}
.layout .ant-layout {
  cursor: pointer;
  position: relative;
}
.d-flex {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}
.color-1 {
  background: #7dbcea !important;
}
.color-2 {
  background: rgba(16, 142, 233, 1);
}
.color-3 {
  background: #3ba0e9 !important;
}
.p-20 {
  padding: 10px 20px;
}
.layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 5px 20px;
}
.layout2-sider,
.layout3-sider,
.layout1-content,
.layout2-content,
.layout3-content,
.layout4-content {
  line-height: 120px !important;
}
.layout4-sider {
  line-height: 248px !important;
}
.layout4-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  transition: 0.2s ease;
  opacity: 1;
  padding: 123.5px;
  cursor: pointer;
}

.layout-preview {
  padding: 20px;
}

.layout1 .ant-layout-header,
.layout2 .ant-layout-header,
.layout3 .ant-layout-header,
.layout4 .ant-layout-header {
  padding-inline: 0px !important;
  text-align: center;
  color: #fff !important;
}
.layout1 .site-layout-content {
  min-height: 280px;
  padding: 24px;
}
.logo {
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo .ant-image {
  width: 100%;
  height: 100%;
  position: inherit;
}
.header-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.ant-layout-footer {
  padding: 24px !important;
  margin: 0 !important;
  min-height: 64px;
  text-align: center;
}
.layout-preview-btn {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding: 15px 15px 0;
}
.layout4 .menu {
  width: 100%;
  text-align: center;
}
.layout .logoText {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff !important;
}
