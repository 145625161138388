.header {
  background: #fff;
  padding: 5px 15px;
  height: 60px;
  display: flex;
  align-items: center;
  box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%),
    0 0.9375rem 1.40625rem rgb(4 9 20 / 3%),
    0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%) !important;
}
.header p {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0;
}
.container,
.inner-container {
  height: 100%;
  width: 100%;
}
.inner-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 5px;
  margin-top: 10px;
}
.pad {
  padding: 7px 10px 0 10px;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
  background: #fff;
}
.editor > .card, .settings > .card {
  min-height: 100px;
  height: 100%;
}
.text-right {
  text-align: right;
}
p, h1, h2, h3, h4, h5, h6 {
  margin: 0 !important;
}
.form-creator svg {
  width: auto;
}
.dynamic-app-container {
  padding: 10px 15px;
}
.dynamic-app-container .ant-input-number {
  width: 100%;
}
.d-flex-align-items{
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex-column{
  display: flex;
  flex-direction: column;
  align-items: baseline;
}