html {
  scroll-behavior: smooth;
}
body {
  box-sizing: border-box;
  background-color: #f5f5f5 !important;
  color: #66737a !important;
  font-weight: 400;
  height: 100vh;
  scroll-behavior: smooth;
}
p {
  margin: 0 !important;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #afafaf;
}
.left-screen {
  width: 250px;
}
.right-screen {
  width: calc(100% - 250px);
}
.left-screen > div, .right-screen > div {
  width: 100%;
}
.sidebar.hide-sidebar {
  display: none !important;
}
.right-screen.w-100 {
  width: 100% !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}