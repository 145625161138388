.menu-creator .ant-form-item-label {
    padding: 0;
}
.menu-creator .menu {
    position: relative;
}
.ant-menu .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 1;
    right: -5px;
    top: 0;
    display: none;
}
.ant-menu-submenu.ant-menu-submenu-active .icon,
.ant-menu-item.ant-menu-item-active .icon {
    display: block;
}
.ant-popover {
    z-index: 9999999;
}
/* .menus .ant-menu-title-content > div {
    display: flex;
} */
/* .ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu> .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  color: red;
  border-bottom: 2px solid red;
} */
