.page-creator-button {
    display: flex;
    gap: 5px;
}
.page-creator-button div, .page-creator-button span,  .page-creator-button svg{
    height: 100%;
    width: 100%;
}
.ant-collapse-header {
    color: inherit !important;
}
.dynamic-app .ant-modal-content {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}