.editor-section, .editor-row, .editor-column{
  position: relative;
}
.editor-section > .icon, .editor-row > .icon, .editor-row > .icon, .editor-column > .icon  {
  display: none;
}
.editor-section .icon,.editor-row .icon, .editor-column .icon{
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 1;
  right: -5px;
  top: 0px;
}
.editor-section .icon svg, .editor-row .icon svg, .editor-column .icon svg {
  height: 100%;
  width: 100%;
}
.editor-column .icon {
  border: none;
}
.editor-section, .editor-row, .editor-column{
  padding: 2px;
  min-height: 17px;
}
.editor-section, .editor-row, .editor-column{
  border: 1.5px dashed transparent;
  transition: all 0.2s ease-out;
}.editor-section.show{
  border: 1.5px dashed #009EFF;
}
.editor-row.show {
  border: 1.5px dashed #68B984;
}
.editor-column.show {
  border: 1.5px dashed #FFD93D;
}
.dynamic-app .editor-row, .dynamic-app .editor-column {
  border-color: transparent !important;
}
.dynamic-app .icon {
  display: none !important;
}